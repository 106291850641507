import { Controller } from "@hotwired/stimulus"
import { getCurrentUserID } from "@concerto/utilities"

export default class extends Controller {
  static values = {
    userId: String,
    content: String
  }

  connect() {
    const currentUserID = getCurrentUserID()

    if (currentUserID === this.userIdValue) {
      this.element.textContent = this.contentValue
    }
  }
}
